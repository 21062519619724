import axios from "axios"

const API_KEY = process.env.VUE_APP_FUSION_API_KEY;
const baseUrl = `${process.env.VUE_APP_FUSION_API_URL}/`
const companyMargins = `${baseUrl}carriers/company_margin_results?API-KEY=${API_KEY}`
const companyMarginsCRUD = `${baseUrl}carriers/company_margins`;

export default {
  async getCompanyServiceMargins(companyId, prepId = false) {
    if (!companyId) throw Error('Please select a company.')
    let url = `${companyMargins}&company_id=${companyId}`
    if(prepId){
      url += `&prep_id=${prepId}`
    }
    let res = await axios.get(url);
    return res.data.data;
  },
  async createCarrierMargin(json) {
    let formData = new FormData;
    for (const key in json) {
      if (json.hasOwnProperty(key)) {
        const element = json[key];
        formData.append(key, element)
      }
    }
    formData.append('API-KEY', API_KEY);
    formData.append('billing_type_id', 1);
    let res = await axios.post(companyMarginsCRUD, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return res.data;
  },
  async updateCarrierMargin(json) {
    var myHeaders = new Headers(); 
    let urlencoded = new URLSearchParams();
    for (const key in json) {
      if (json.hasOwnProperty(key)) {
        const element = json[key];
        urlencoded.append(key, element)
      }
    }   
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    urlencoded.append('API-KEY', API_KEY);
    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    let result = await fetch(companyMarginsCRUD, requestOptions)
      .then(response => response.text())
      .then(result => this.result = result)
    return result;   
  },
}